










































import { Logger, LogLevel } from 'truemarket-modules/src/debug/Logger'
import { Config } from 'truemarket-modules/src/environment'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
  private loading = true;
  private isLockedOut = false;
  private wrongPassword = false;
  private isPasswordReset = false;

  private email = '';
  private password = '';

  mounted () {
    this.loading = false

    this.isPasswordReset = this.$route.query.reset as string === 'true'
  }

  async submit () {
    this.loading = true

    const loginResult = await Services.API.Auth.Login({
      Email: this.email,
      Password: this.password
    })

    if (loginResult.Success && loginResult.Token) {
      Services.CredentialManager.SetToken(loginResult.Token)
      window.location.href = '/dashboard/admin/temp_valuations'
      return
    }

    this.isLockedOut = loginResult.IsLockedOut
    this.wrongPassword = !loginResult.Success
    this.loading = false
  }
}
